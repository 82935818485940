<template>
  <div id="app">

    <!-- 用户 -->
    <div class="white_box3" style="margin-top: 16px;">
      <div class="user shadow clear" style="padding: 16px 12px;">
        <div class="user_msg_all clear" v-if="customerUser">
          <div class="user_avatar" :style="{backgroundImage:`url(${img})`}"></div>
          <div class="user_msg" style="width: auto; flex: 1;">
            <span v-if="customerUser.businessCustomerUser">{{ customerUser.businessCustomerUser.name }}</span>
            <label
                v-if="customerUser.businessCustomerUser">{{
                customerUser.businessCustomerUser.business.name
              }}，{{ customerUser.businessCustomerUser.structure.name }}</label>
          </div>
          <div :class="getTradingStateClass(data.productBundle.tradingState)">{{ getTradingState(data.productBundle.tradingState) }}</div>
        </div>
      </div>
    </div>

    <!-- 餐次信息 -->
    <div class="white_box3" style="padding: 16px 12px; margin-top: 12px;">
      <div style="color: #222222; line-height: 16px; font-size: 16px; font-weight: bold; flex: 1;">{{
          data.productBundle.name
        }}
      </div>
      <div style="margin-top: 16px;">
        <div style="display: flex;">
          <div style="color: #fc4353; font-size: 12px; line-height: 12px; align-self: flex-end; margin-bottom: 1px;">
            {{ '¥' }}
          </div>
          <div style=" font-weight: bold; color: #fc4353; line-height: 14px; margin-left: 1px;">
            {{ formatAmountAndInitZero(data.productBundle.totalAmount) }}
          </div>

          <div style="color: #A1A1A1; font-size: 12px; line-height: 14px; margin-left: 16px;">
            {{
              '餐标' + formatAmountAndInitZero(data.productBundle.singlePrice) + '元/餐, 共' + (data.productBundle.mealDays ? data.productBundle.mealDays : '0') + '餐'
            }}
          </div>
        </div>
      </div>

      <div style="height: 1px; background: #F1F1F1; margin: 12px 0;"></div>

      <div class="orderList_s" style="padding: 0px;">
        <ul>
          <li class="clear" style="font-size: 12px;">
            <span>报名开始时间：</span>
            <label>{{ data.productBundle.signUpStartTime | moment('yyyy-MM-DD HH:mm') }}</label>
          </li>
          <li class="clear" style="font-size: 12px;">
            <span>报名结束时间：</span>
            <label>{{ data.productBundle.signUpEndTime | moment('yyyy-MM-DD HH:mm') }}</label>
          </li>
          <li class="clear" style="font-size: 12px;">
            <span>缴费开始时间：</span>
            <label>{{ data.productBundle.paymentStartTime | moment('yyyy-MM-DD HH:mm') }}</label>
          </li>
          <li class="clear" style="font-size: 12px; padding-bottom: 0;">
            <span>缴费结束时间：</span>
            <label>{{ data.productBundle.paymentEndTime | moment('yyyy-MM-DD HH:mm') }}</label>
          </li>
        </ul>
      </div>
    </div>

    <!-- 报名信息 -->
    <div class="white_box3"
         v-if="data.productBundle.type === 'SIGN_UP' && (data.productBundle.tradingState === 'COMPLETE_RECHARGE' || data.productBundle.tradingState === 'SIGN_UP_AND_WAIT_RECHARGE' || data.productBundle.tradingState === 'SIGN_UP_AND_PAYMENT_NOT_START')"
         style="padding: 16px 12px; margin-top: 12px;">
      <div
          style="color: #222222; line-height: 14px; font-size: 14px; flex: 1; border-left: 2px #34AC40 solid; padding-left: 4px;">
        报名信息
      </div>
      <div class="orderList_s" style="padding: 0px; margin-top: 16px;">
        <ul>
          <li class="clear" style="font-size: 12px;">
            <span>报名人：</span>
            <label>{{ data.signUpCustomerName }}</label>
          </li>
          <li class="clear" style="font-size: 12px;">
            <span>报名时间：</span>
            <label>{{ data.signUpTime | moment('yyyy-MM-DD HH:mm:ss') }}</label>
          </li>
        </ul>
      </div>
    </div>

    <!-- 报名信息 -->
    <div class="white_box3"
         v-if="data.productBundle.tradingState === 'COMPLETE_RECHARGE'"
         style="padding: 16px 12px; margin-top: 12px;">
      <div
          style="color: #222222; line-height: 14px; font-size: 14px; flex: 1; border-left: 2px #34AC40 solid; padding-left: 4px;">
        缴费信息
      </div>
      <div class="orderList_s" style="padding: 0px; margin-top: 16px;">
        <ul>
          <li class="clear" style="font-size: 12px;">
            <span>缴费账户：</span>
            <label>{{ data.rechargeCustomerName }}</label>
          </li>
          <li class="clear" style="font-size: 12px;">
            <span>缴费时间：</span>
            <label>{{ data.rechargeTime | moment('yyyy-MM-DD HH:mm:ss') }}</label>
          </li>
        </ul>
      </div>
    </div>

    <!-- 底部按钮 -->
    <div
        style="width: 100%; height: 13vw; display: flex; background: #fff; position: fixed; left: 0; bottom: 0; align-items: center; padding: 0px 12px;"
        v-if="data.productBundle.tradingState && data.productBundle.tradingState !== 'COMPLETE_RECHARGE'">
      <button v-if="showApplyBtn()"
              style="flex: 1; height: 9vw; background-image: linear-gradient(to right, #39d148 , #34ac40); color: #fff; border: none; box-sizing: border-box; border-radius: 50px; border-bottom-right-radius: 50px;"
              @click="onClickApply()">
        报名搭食
      </button>

      <button v-if="showCancelApplyBtn()"
              style="flex: 1; height: 9vw; background: #A1A1A1; color: #fff; border: none; box-sizing: border-box; border-radius: 50px; border-bottom-right-radius: 50px;"
              @click="onClickCancelApply()">
        取消报名
      </button>

      <div v-if="showCancelApplyBtn && showRechargeBtn" style="width: 12px;"></div>

      <button v-if="showRechargeBtn()"
              style="flex: 1; height: 9vw; background-image: linear-gradient(to right, #39d148 , #34ac40); color: #fff; border: none; box-sizing: border-box; border-radius: 50px; border-bottom-right-radius: 50px;"
              @click="onClickRecharge()">
        缴费
      </button>
    </div>

    <!-- 结果弹窗 -->
    <van-dialog v-model="showResult" confirm-button-color="#34ac40" confirm-button-text="缴费"
                @confirm="onDialogClickConfirmRouteToRecharge"
                :show-confirm-button="showDialogConfirmButton"
                show-cancel-button cancel-button-text="关闭" cancel-button-color="#A1A1A1">
      <div class="feedback_in" style="padding: 24px 0 24px 0">
        <div style="text-align: -webkit-center;">
          <div style="height: 100px; width: 100px;" :style="{backgroundImage:`url(${resultIconUrl})`}"/>
        </div>
        <div style="color: #222222; margin-top: 4px;">{{ resultState }}</div>
        <div v-if="resultTitle !== ''" style="color: #222222; font-size: 12px; line-height: 12px; margin-top: 4px;">
          {{ resultTitle }}
        </div>
        <div v-if="resultCreatedBy !== ''" style="color: #A1A1A1; font-size: 12px; line-height: 12px; margin-top: 8px">
          {{
            '提交人: ' + resultCreatedBy
          }}
        </div>
        <div style="color: #A1A1A1; font-size: 12px; line-height: 12px; margin-top: 8px">{{
            '提交时间: ' + resultCreatedTime
          }}
        </div>
      </div>
    </van-dialog>

    <van-popup v-model="showConfirmPopup" round position="bottom" :style="{ height: '45%' }" closeable
               :close-on-click-overlay="false"
               close-icon="close">
      <div style="height: 100%; margin-top: 48px;">
        <div style="text-align: center; font-size: 24px; line-height: 24px; font-weight: bold; color: #222222;">
          {{ '¥ ' + formatAmountAndInitZero(data.productBundle.totalAmount) }}
        </div>
        <div style="text-align: center; margin-top: 6px; margin-bottom: 8px; color: #333333;">{{ data.productBundle.name }}</div>
        <div v-if="paymentInfo && paymentInfo.needPassword === '1'">
          <div style="display: flex; margin: 12px 16px 0 16px;">
            <div style="flex: 1;">
              {{ '付款帐号' }}
            </div>
            <div>
              <div>{{ bindingCardNo }}</div>
              <div style="font-size: 13px; float: right; margin-top: 4px; color: #999999;">{{ bindingCardBankName }}</div>
            </div>
          </div>

        </div>
        <!-- 零钱抵扣 -->
        <div v-if="showFreedomDeductible">
          <van-cell
              :border="false"
              :clickable="false"
              :title="freedomTotalAmountStr"
          >
            <template #right-icon>
              <div style="margin-right: 4px;">{{ canUseFreedomDeductibleAmountStr }}</div>
              <van-checkbox v-model="checkedUseFreedomDeductibleAmount" @click="onClickFreedomDeductible"/>
            </template>
          </van-cell>
        </div>

        <div style="position: absolute; bottom: 16px; width: 100%;">
          <div class="agreen clear" style="color: #D1D1D1; padding-bottom: 12px;"
               v-if="paymentInfo && paymentInfo.needPassword === '1'">
            点击按钮即代表同意协议
            <font @click="showNotice2 = true" style="color: #34AC40">《广发银行委托代扣协议书》</font>
          </div>

          <div
              style="background: #34AC40; border-radius: 4px; color: #FFFFFF; width: calc(100% - 32px); margin: 0 16px; padding: 12px 0px; text-align: center; font-size: 16px; line-height: 16px;"
              @click="onClickConfirmRecharge">
            立即支付 {{ '¥ ' + formatAmountAndInitZero(rechargeAmount) }}
          </div>
        </div>

      </div>
    </van-popup>

    <van-popup style="z-index: 10000" v-model="showPasswordInputPopup" :close-on-click-overlay="false">
      <div class="bank3_title" style="padding: 40px 0;">请输入支付密码</div>
      <div class="yzm2">
        <van-password-input
            :value="password"
            :gutter="10"
            :focused="showPasswordKeyboard"
            @focus="showPasswordKeyboard = true"
        />
      </div>
      <div style="text-align: center; padding: 24px 0;">
        <div
            style="position: absolute; padding-top: 28px; color: rgb(28, 122, 235); margin-right: 24px; right: 0; font-size: 12px;"
            @click="$router.push({path: '/verification'})">
          忘记支付密码?
        </div>
        <a-button type="link" @click="onClickCancelInputPasswordToRecharge" class='cancel_recharge'>
          取消充值
        </a-button>
      </div>

    </van-popup>

    <van-number-keyboard
        v-model="password"
        :maxlength="6"
        :show="showPasswordKeyboard"
        @blur="showPasswordKeyboard = false"
        style="z-index: 20000;"
    />

    <!-- 弹出协议2 -->
    <div class="bg_dark" style="z-index: 2006;" v-show="showNotice2">
      <div class="bank_notice">
        <div class="bank_notice_t">
          广发银行委托代扣协议书
          <span class="iconfont" @click="showNotice2 = false">&#xe740;</span>
        </div>
        <div class="bank_notice_b">
          广发银行委托代扣协议书（以下简称“本协议”）是广发银行股份有限公司（以下简称“广发银行”）与个人用户（以下简称“您”）就广发银行代扣业务的相关事宜达成的协议。您通过点击“同意”或以其他方式选择接受本协议，即表示您知悉并同意接受本协议的全部内容同意按照本协议约定履行义务。在确认接受本协议之前，请您仔细阅读本协议的全部内容。如果您不同意本协议的任何内容，或者无法准确理解相关条款的解释，请您立即关闭相关办理业务，务必不要进行后续任何操作。<br>
          第一条 定义<br>
          广发银行委托代扣服务，是指广发银行根据您的授权，执行收款人向您在广发银行开立账户发起的扣款指令，无须经交易验证直接完成将您账户资金扣划至收款人账户的服务（以下简称“代扣服务”）。<br>
          第二条 个人用户的权利义务<br>
          （一）您申请开通代扣服务时，签约本人名下的广发银行账户，您保证提供给广发银行的身份信息、身份资料的真实性、准确性、完整性、合法性和有效性。您应当对自己的账户信息进行严格保密和控制，通过相关账户信息进行签约操作的行为均视为您本人的行为。<br>
          （二）您应妥善保管本人包括但不限于身份信息、银行账号、银行账号密码以及手机动态验证码等安全认证工具，不得将以上任何信息向他人透露，否则由此导致的损失与后果将由您本人自行承担，广发银行不因此承担任何责任。<br>
          （三）您保证业务开通时在广发银行预留的手机号为本人手机号码且与在收款人注册时预留的手机号一致，因您未及时更新本人在广发银行预留的手机号码等信息导致本服务无法提供或提供时发生错误，由此产生的后果和损失将由您自行承担。<br>
          （四）您承诺不会利用本服务从事任何非法的或侵犯任何其他第三方权益的行为，否则广发银行有权立即单方面终止为您提供本协议项下代扣服务，并依法采取相应的法律措施；如果因您的行为给广发银行造成损失的，您应承担全部责任。<br>
          （五）您保证账户余额充足、账户状态正常，确保广发银行能够按照扣款信息及时付款。<br>
          （六）您授权广发银行根据收款人的支付指令从您的账户中扣划指定款项至收款人指定的银行账户。授权具体事项以您与广发银行签订的代扣信息为准。<br>
          （七）如您的任一授权事项发生变更时，请及时通过广发银行柜面、手机银行等渠道变更，因您未及时更新授权信息，导致本服务无法提供或提供时发生错误，由此产生的后果将由您自行承担。<br>
          （八）您保证遵循反洗钱法律法规及监管规定，切实履行反洗钱、反恐怖融资和扩散融资、反逃税义务，资金来源和用途不涉及违规、违法活动。您如因涉嫌洗钱、恐怖融资和扩散融资、逃税、制裁等违法犯罪活动或违反相关法律规定引起纠纷的，自愿承担相应的法律责任。<br>
          第三条 广发银行的权利义务<br>
          （一）广发银行有义务在交易过程中对授权事项进行逐笔验证，如未与您建立代扣授权关系或者授权事项不符的，广发银行有权拒绝办理相关业务。<br>
          （二）广发银行有权对您进行客户尽职调查，您应积极配合并提供真实、准确、完整、合法、有效的相关信息和资料；如您不配合，您同意广发银行有权中止交易，直至终止业务关系。<br>
          （三）广发银行通过微信银行、手机银行、短信等其中之一方式通知您代扣相关信息。<br>
          （四）广发银行有义务在技术上确保代扣服务的安全、有效、正常运行，保证您的正常使用。<br>
          （五）广发银行可以在法律法规许可或您授权的范围内使用您在广发银行留存的资料或交易信息等。<br>
          （六）如广发银行原因造成错扣、漏扣、多扣的，经核实后，广发银行应对其造成的失误承担及时纠正责任。<br>
          （七）广发银行在终止提供本服务后，若发现您之前存在违法或违反本协议目的的使用行为给广发银行造成损失的，则广发银行仍可据此要求您承担相应赔偿责任并保留通过司法途径予以解决的权利。<br>
          第四条 授权终止<br>
          （一）您可通过广发银行柜面、手机银行等渠道发起终止已授权的代扣服务。如您通过广发银行发起终止授权，您应及时通知收款人，如后期收款人未能按照约定完成代扣交易，因此产生的资金纠纷及相关责任由您自行承担。<br>
          （二）您通过广发银行渠道终止代扣服务的，本协议终止，您后续仍需使用广发银行代扣服务，可重新发起签约开通代扣服务。<br>
          （三）如您怀疑签约的账户信息发生泄露，可通过广发银行柜面、手机银行等渠道发起终止已授权的代扣服务，或者立即通知广发银行并按照有关挂失、销户的规定办理账户挂失、销户。<br>
          第五条 异议处理<br>
          （一）在本协议下广发银行是支付指令的执行方，涉及收款人以及交易的真实性准确性，以及扣款环节中遇到的任何问题（因广发银行原因导致的除外），由您与收款人协调解决，广发银行可在法律法规及监管规定允许的前提下提供必要的协助。<br>
          （二）您知晓并同意，广发银行仅根据本协议约定向您提供代扣服务，对于您与收款人之间发生的纠纷，由您与收款人协商处理。如您对扣款用途、扣款金额等有异议由您与收款人协商处理。<br>
          （三）如因收款人向广发银行传送授权事项有误，导致广发银行不能按约定扣划资金或致使您及收款人发生损失的，广发银行不承担责任，由您与收款人协商解决。<br>
          （四）因通讯等原因导致您未能及时接收广发银行发出的代扣信息，您可以通过广发银行的手机银行、营业网点查询。<br>
          第六条 不可抗力<br>
          由于不能预见、不能克服、不能避免等不可抗力或不能控制等客观因素导致通讯或系统中断，以致影响代扣业务不能正常完成的，您认可并同意广发银行无需承担任何法律责任。<br>
          第七条 特别约定条款<br>
          （一）广发银行对本协议有权进行必要的修改，修改的条款对办理本项业务的所有代扣用户均有约束力。广发银行将通过官网、短信等其中之一方式公示，公示时间30日，如您不同意修改条款，有权在公示期内注销本协议代扣功能，公示期满，您未提出注销代扣功能的，视同您认可修改后的条款对您的约束力。<br>
          （二）为了校验信息的准确性，对您的身份及其他必要信息进行识别、验证，您同意授权广发银行向相关第三方提供您的信息，包括姓名、账号、证件号码、联系方式、用途、限额、协议号等。对于上述信息，广发银行会根据相关法律法规及银行相关信息保护制度规定进行共享，并要求合作方履行信息保密义务。您知晓本协议中信息被提供和使用的风险，这些风险包括但不限于：该等信息被广发银行依法提供给第三方后被他人不当利用的风险，由于除广发银行以外的原因造成的故障以及其他不可抗力因素导致的隐私泄密的风险，或因您的业务状况较好而造成被接收信息的第三方营销产品或服务等打扰的风险。<br>
          （三）本协议适用中华人民共和国法律。为本协议之目的，中华人民共和国法律不包括香港特别行政区、澳门特别行政区及台湾地区法律。<br>
          （四）因履行本协议而产生的或与本协议有关的任何争议，应协商解决；协商不成的，可以向有管辖权人民法院提起诉讼。<br>
          （五）自您同意接受本协议之日起生效。您在协议签约中提供的授权事项为本协议的一部分，与本协议具有同等法律效力。<br>
          用户确认:您确认已仔细阅读、理解并接受了本协议的全部条款和内容，对本协议条款的含义及相应的法律后果已全部知晓并充分理解，愿意遵守本协议全部内容；本人确认，广发银行已就增加本人责任或限制本人权利、免除或限制广发银行责任或广发银行单方拥有某些权利的所有条款向本人作出相应提示和说明，本人对所有条款完全理解并自愿接受该内容。
        </div>
      </div>
    </div>

    <div class="loading_all" v-if="loading">
      <van-loading type="spinner" color="#ffffff" vertical>{{ loadingContent }}</van-loading>
    </div>

  </div>
</template>

<script>
import Vue from "vue";
import {ACCESS_CustomerUser} from "../../store/mutation-types";
import {post} from "../../utils/http";
import {Dialog, Notify, Toast} from "vant";
import moment from "moment";
import {formatAmountAndInitZero, guid} from "../../utils/util";
import {toDecimal} from "../../utils/decimals";

export default {
  name: "apply_recharge_detail",
  data() {
    return {
      loading: true,
      loadingContent: '加载中...',

      customerUser: null,
      img: require('../../assets/images/avatar.png'),
      img2: require('../../assets/images/avatar_relevance.png'),

      id: null,
      data: {
        productBundle: {}
      },
      paymentInfo: null,

      showResult: false,
      showDialogConfirmButton: false,
      resultIconUrl: require('../../assets/images/ico_success2.svg'),
      resultState: '',
      resultTitle: '',
      resultCreatedBy: '',
      resultCreatedTime: '',

      showPasswordInputPopup: false,
      password: '',
      showPasswordKeyboard: false,

      showConfirmPopup: false,
      showNotice2: false,
      rechargeAmount: 0,
      bindingCardNo: '',
      bindingCardBankName: '',
      sign: '',
      deposit: null,

      showFreedomDeductible: false,
      checkedUseFreedomDeductibleAmount: false,
      freedomTotalAmountStr: null,
      freedomDeductibleAmount: 0,
      canUseFreedomDeductibleAmount: 0,
      canUseFreedomDeductibleAmountStr: null,
    }
  },
  mounted() {
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL);
      window.addEventListener('popstate', this.goBack, false);
    }

    this.customerUser = Vue.ls.get(ACCESS_CustomerUser);

    this.id = this.$route.query.id;
    if (!this.id) {
      this.goBack();
      return;
    }

    this.requestBusinessPaymentInfo();
    this.fetchData();
  },
  watch: {
    password(val) {
      if (val.length === 6) {
        this.showPasswordKeyboard = false;
        this.showPasswordInputPopup = false;
        this.requestConfirmRechargeDepositAndRecharge();
      }
    }
  },
  destroyed() {
    window.removeEventListener('popstate', this.goBack, false);
  },
  methods: {
    goBack() {
      this.$router.push({path: '/recharge/applyRechargeList'});
      window.history.pushState('forward', null, '');
      window.history.forward(1);
    },

    setLoading(loading, content = '加载中...') {
      this.loading = loading;
      this.loadingContent = content;
    },
    formatAmountAndInitZero(amount) {
      return formatAmountAndInitZero(amount);
    },

    fetchData() {
      this.setLoading(true);
      const url = 'api/business/v2/{businessId}/product/bundle/{productBundleId}/{businessCustomerUserId}/read'
          .replace('{businessId}', this.customerUser.businessId)
          .replace('{productBundleId}', this.id)
          .replace('{businessCustomerUserId}', this.customerUser.businessCustomerUserId);
      post(url, {}).then(res => {
        this.data = res.data;
        // if (this.data.productBundle.tradingState === 'NOT_SIGN_UP') {
        //   this.showApplyBtn = true;
        //   this.showRechargeBtn = false;
        // } else if (this.data.productBundle.tradingState === 'SIGN_UP_AND_WAIT_RECHARGE') {
        //   this.showApplyBtn = true;
        //   this.showRechargeBtn = true;
        // } else if (this.data.productBundle.tradingState === 'SIGN_UP_AND_PAYMENT_NOT_START') {
        //   this.showApplyBtn = true;
        //   this.showRechargeBtn = false;
        // }
      }).catch(err => {
      }).finally(() => {
        this.setLoading(false);
      })

    },
    requestBusinessPaymentInfo() {
      post("api/business/v2/{businessId}/business/customer/user/paymentInfoQuery".replace('{businessId}', this.customerUser.businessId))
          .then(result => {
            this.paymentInfo = result.data;
            if (this.paymentInfo.code === 'GF') {
              this.requestCustomerUserCardNo();
            }
          })
          .catch(error => {
            console.log(error.message);
          })
    },
    requestCustomerUserCardNo() {
      post('api/customer/account/v2/own/read/cardNo', {}).then(res => {
        if (res.data) {
          this.bindingCardNo = res.data.bindingCardNo;
          this.bindingCardBankName = res.data.bindingCardBankName;
        } else {
          Toast("充值异常，请联系客服处理");
        }
      }).catch(() => {
        Toast("充值异常，请联系客服处理");
      })
    },
    // 获取零钱抵扣金额
    requestFreedomDeductible() {
      post('api/guangfa/v3/checkBeforeRecharge', {
        businessCustomerUserId: this.customerUser.businessCustomerUserId
      }).then(res => {
        let isZero = true;
        if (res.data) {
          let actualFreedomDeductibleAmount = 0;
          this.deductibleData = res.data;
          this.rechargeAmount = this.data.productBundle.totalAmount;

          // this.deductibleData = {totalAmount: 10};

          if (this.deductibleData.totalAmount > 0) {
            this.deductibleData.deductibleList.forEach(deductible => {
              if ("GENERAL" === deductible.id) {
                actualFreedomDeductibleAmount += deductible.amount;
              } else if (this.data.productBundle.paymentPlatformChannelId === deductible.id) {
                actualFreedomDeductibleAmount += toDecimal(deductible.amount);
              }
            });

            if (actualFreedomDeductibleAmount > 0) {
              isZero = false;

              this.showFreedomDeductible = true;
              this.freedomDeductibleAmount = actualFreedomDeductibleAmount;
              this.freedomTotalAmountStr = "零钱抵扣（总 " + this.deductibleData.totalAmount + ", 可用" + actualFreedomDeductibleAmount + "）";

              if (this.deductibleData.totalAmount >= this.data.productBundle.totalAmount) {
                this.canUseFreedomDeductibleAmount = this.data.productBundle.totalAmount;
              } else {
                this.canUseFreedomDeductibleAmount = actualFreedomDeductibleAmount;
              }
              this.canUseFreedomDeductibleAmountStr = "抵扣 ¥ " + this.canUseFreedomDeductibleAmount;

              this.checkedUseFreedomDeductibleAmount = true;
              this.rechargeAmount = this.data.productBundle.totalAmount - this.canUseFreedomDeductibleAmount;
            }
          }
        }

        if (isZero) {
          this.freedomDeductibleAmount = 0;
          this.showFreedomDeductible = false;
          this.freedomTotalAmountStr = null;
          this.canUseFreedomDeductibleAmountStr = null;
        }

        this.showConfirmPopup = true;

        this.loading = false;

      }).catch(() => {
        this.loading = false;
      });
    },
    // 1.下单
    requestCreateRechargeDeposit() {
      this.setLoading("生成充值订单中");
      if (!this.sign || this.sign === '') {
        this.sign = guid();
      }

      this.deposit = {};

      let actualFreedomDeductibleAmount = 0;
      let productBundleAmount = toDecimal(this.data.productBundle.totalAmount);
      if (this.checkedUseFreedomDeductibleAmount) {
        actualFreedomDeductibleAmount = this.freedomDeductibleAmount;
      }
      actualFreedomDeductibleAmount = toDecimal(actualFreedomDeductibleAmount);

      post('api/pay/deposit/recharge/create', {
        productBundleIds: [this.data.productBundle.id],
        freedomAmount: 0,
        productBundleAmount: productBundleAmount,
        deductibleAmount: actualFreedomDeductibleAmount,
        businessCustomerUserId: this.customerUser.businessCustomerUserId,
        sign: this.sign,
      }).then(result => {
        this.deposit = result.data;
        if (this.deposit) {
          if (this.deposit.needPassword === '1') {
            this.loading = false;
            this.showPasswordInputPopup = true;

          } else {
            // 直接确认
            this.requestConfirmRechargeDepositAndRecharge();
          }

        }

      }).catch(error => {
        this.loading = false;
        this.sign = guid();
        Notify({type: "warning", message: error.message});

      });

    },
    // 2.确认
    requestConfirmRechargeDepositAndRecharge() {
      if (!this.deposit) {
        Notify({type: "warning", message: "充值异常，请重新充值（-1）"});
        return;
      }

      this.setLoading("支付中,请稍后");

      post('api/pay/deposit/recharge/confirm', {
        depositId: this.deposit.depositId,
        password: this.password
      }).then(res => {
        if (res.data && res.data.payUrl) {
          // 跳转第三方页面
          window.location.href = res.data.payUrl;
        } else {
          // 广发钱包支付
          this.resultIconUrl = require('../../assets/images/ico_success2.svg');
          this.resultState = '充值成功';
          this.resultTitle = '';
          this.resultCreatedBy = res.createdBy;
          this.resultCreatedTime = moment(res.createdTime).format('YYYY-MM-DD HH:mm:ss');
          this.showResult = true;

          this.sign = guid();
          this.requestBusinessPaymentInfo();
          this.fetchData();

        }

        this.loading = false

      }).catch(error => {
        if (error.message.indexOf('交易流水号:') != -1) {
          let no = error.message.split(':')[1]
          this.requestGFRechargeResultCheck(no);
        } else {
          Dialog.alert({title: '充值失败', message: error.message}).then(() => {
            this.sign = guid();
            this.requestBusinessPaymentInfo();
            this.fetchData();
          });
        }
        this.loading = false
      });

    },
    requestGFRechargeResultCheck(no) {
      Dialog.alert({
        message: '银行系统繁忙！如您已确认成功支付，请点击确认按钮重新刷新支付结果',
        showCancelButton: true
      }).then(() => {
        // no
        this.loading = true
        let url = 'api/guangfa/v2/' + no + '/doubleCheck/log';
        post(url, {}).then(() => {
          Dialog.alert({message: '充值成功'}).then(() => {
            this.switchUser(this.customerUser);
            this.$router.push({path: '/customer_user_account'})
          });
          this.loading = false
        }).catch(error => {
          this.check(no)
          this.loading = false
        });
      });
    },

    onClickApply() {
      if (!this.data) {
        return;
      }

      Dialog.confirm({
        message: '确认报名?',
        confirmButtonText: '确认',
        cancelButtonText: '取消'
      }).then(() => {
        this.setLoading(true, '提交中...');
        const url = 'api/business/v2/{businessId}/product/bundle/signUp'.replace('{businessId}', this.customerUser.businessId);
        post(url, {
          businessCustomerUserId: this.customerUser.businessCustomerUserId,
          productBundleIdList: [
            this.id
          ]
        }).then(res => {
          this.resultIconUrl = require('../../assets/images/ico_success2.svg');
          this.resultState = '报名成功';
          this.resultTitle = '请及时进行缴费哦~';
          this.resultCreatedBy = res.data[0].createdBy;
          this.resultCreatedTime = moment(res.data[0].createdTime).format('YYYY-MM-DD HH:mm:ss');
          this.showDialogConfirmButton = true;
          this.showResult = true;
          this.fetchData();
        }).catch(err => {
          this.resultIconUrl = require('../../assets/images/ico_fail.svg');
          this.resultState = '报名失败';
          this.resultTitle = err.message;
          this.resultCreatedTime = moment(err.timestamp).format('YYYY-MM-DD HH:mm:ss');
          this.showDialogConfirmButton = false;
          this.showResult = true;
        }).finally(() => {
          this.setLoading(false);
        })

      }).catch(() => {
      })

    },
    onClickCancelApply() {
      if (!this.data) {
        return;
      }

      Dialog.confirm({
        message: '确认取消报名?',
        confirmButtonText: '确认',
        cancelButtonText: '取消'
      }).then(() => {
        this.setLoading(true, '提交中...');
        const url = 'api/business/v2/{businessId}/product/bundle/signUp/cancel'.replace('{businessId}', this.customerUser.businessId);
        post(url, {
          businessCustomerUserId: this.customerUser.businessCustomerUserId,
          productBundleId: this.id
        }).then(res => {
          this.resultIconUrl = require('../../assets/images/ico_success2.svg');
          this.resultState = '取消报名成功';
          this.resultTitle = '';
          this.resultCreatedBy = res.data.createdBy;
          this.resultCreatedTime = moment(res.data.createdTime).format('YYYY-MM-DD HH:mm:ss');
          this.showDialogConfirmButton = false;
          this.showResult = true;
          this.fetchData();
        }).catch(err => {
          this.resultIconUrl = require('../../assets/images/ico_fail.svg');
          this.resultState = '取消报名失败( ' + err.status + '), 请重试';
          this.resultTitle = err.message;
          this.resultCreatedTime = moment(err.timestamp).format('YYYY-MM-DD HH:mm:ss');
          this.showDialogConfirmButton = false;
          this.showResult = true;
        }).finally(() => {
          this.setLoading(false);
        })

      }).catch(() => {
      })

    },
    onClickRecharge() {
      if (!this.data) {
        return;
      }
      if (this.paymentInfo.code === 'GF' && (!this.bindingCardNo || this.bindingCardNo === '')) {
        return;
      }

      this.setLoading(true);
      this.requestFreedomDeductible();

    },
    onDialogClickConfirmRouteToRecharge() {
      this.routeToRecharge();
    },
    onClickConfirmRecharge() {
      let rechargeFreedomAmount = toDecimal(this.rechargeFreedomAmount);
      if (rechargeFreedomAmount <= 0) {
        Notify({type: 'warning', message: '请填写您要充值的金额'});
        return;
      }

      this.setLoading("检查充值环境中");
      this.showConfirmPopup = false;

      if (this.paymentInfo.code === 'GF') {
        // 增加限额控制
        // 检查是否二类户
        post('api/customer/account/v2/{businessCustomerUserId}/find'.replace("{businessCustomerUserId}", this.customerUser.businessCustomerUserId), {}, true)
            .then(res => {
              const customerAccount = res.data;

              if (!customerAccount.id) {
                this.$router.push({path: '/AccountAgreement'});
                return;
              }
              // 广发
              if (customerAccount.accountOpenType === '3') {
                // 检查影像状态？
                // 初审成功
                if (customerAccount.auditStatus && customerAccount.auditStatus !== '03') {
                  this.loading = false;
                  Dialog.alert({title: '温馨提示', message: '您已成功提交资料进行升级，\n广发银行将于1-3个工作日内完成审核，\n审核结果以短信通知，敬请留意。'});
                  return;
                } else if (customerAccount.auditStatus && customerAccount.auditStatus === '03') {
                  Dialog.confirm({title: '温馨提示', message: '账户升级失败，请您再次升级或联系客服人员', confirmButtonText: '升级'})
                      .then(() => {
                        this.$router.push({path: '/AccountUpgradeDescription'})

                      })
                      .catch(() => {

                      });
                  return;
                }

                // 1. 获取广发余额
                post('api/guangfa/v2/queryAccountBalance', {}).then(res => {
                  let balance = toDecimal(res.data.eAccAmt)
                  // 2. 根据 充值金额+广发余额 是否 > 2000 进行比较
                  if ((toDecimal(this.showAmount) + balance) > 2000) {
                    // 3. 大于2000 提示升级，进入升级流程
                    this.loading = false;
                    Dialog.confirm({title: '温馨提示', message: '您当前的充值限额为2000,\n需升级后才能正常使用', confirmButtonText: '升级'})
                        .then(() => {
                          this.$router.push({path: '/AccountUpgradeDescription'})

                        })
                        .catch(() => {

                        });

                  } else {
                    this.requestCreateRechargeDeposit();
                  }

                }).catch(error => {
                  Notify({type: 'warning', message: error.message});
                  this.loading = false;

                });

              } else if (customerAccount.accountOpenType === '2') {
                if (customerAccount.auditStatus === '03') {
                  // 二审成功
                  this.requestCreateRechargeDeposit();
                  return;

                }
                if (customerAccount.auditStatus === '04') {
                  // 二审失败
                  this.$router.push({
                    path: '/AccountCreateFeedback',
                    query: {type: 'UPGRADE_FAIL', msg: customerAccount.auditRemark}
                  });

                } else if (customerAccount.auditStatus === '05' || customerAccount.auditStatus === '06') {
                  // 等待影相再审核
                  this.$router.push({
                    path: '/AccountCreateFeedback',
                    query: {type: 'ID_CARD_REVIEWING'}
                  });

                }
                this.loading = false;
              }

            }).catch(() => {
          Notify({type: 'warning', message: '充值异常，请重试'});
        });
      } else {
        this.requestCreateRechargeDeposit();

      }
    },
    onClickCancelInputPasswordToRecharge() {
      this.password = '';
      this.showPasswordInputPopup = false;
    },
    onClickFreedomDeductible() {
      console.log(this.checkedUseFreedomDeductibleAmount);
      if (this.checkedUseFreedomDeductibleAmount) {
        this.rechargeAmount = this.data.productBundle.totalAmount - this.freedomDeductibleAmount;
      } else {
        this.rechargeAmount = this.data.productBundle.totalAmount;
      }
    },

    routeToRecharge() {
      if (!this.paymentInfo) {
        return;
      }
      if (this.paymentInfo.needPassword === '0') {
        this.$router.push({path: '/recharge'});
        return;
      }

      this.loading = true;
      post('api/customer/account/v2/:businessCustomerUserId/find'.replace(':businessCustomerUserId', this.customerUser.businessCustomerUserId), {}, true).then(res => {
        const customerAccount = res.data;

        if (this.paymentInfo.code === 'GF') {
          if (!customerAccount.id) {
            this.$router.push({path: '/AccountAgreement'});
            return;
          } else if (!this.checkAccountState(customerAccount)) {
            return;
          }
        }

        // if (!this.customerAccount || !this.customerAccount.id
        //     || (this.customerAccount.auditStatus === 'YL' && this.paymentInfo.code === 'GF')) {
        //   this.$router.push({path: '/AccountAgreement'});
        //   return;
        // }
        //
        // if (!this.checkAccountState()) {
        //   return;
        // }
        this.$router.push({path: '/recharge', query: {backUrl: 'applyRechargeList'}});

        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },
    checkAccountState(customerAccount) {
      if (!customerAccount) {
        return false;
      }
      if (customerAccount.auditStatus === '04') {
        // 二审失败
        this.$router.push({
          path: '/AccountCreateFeedback',
          query: {type: 'UPGRADE_FAIL', msg: customerAccount.auditRemark}
        });
        return false;

      } else if (customerAccount.auditStatus === '05') {
        // 等待影相再审核
        this.$router.push({path: '/AccountCreateFeedback', query: {type: 'ID_CARD_REVIEWING'}});
        return false;

      }
      return true;
    },

    showApplyBtn() {
      return this.data.productBundle.tradingState === 'NOT_SIGN_UP';
    },
    showCancelApplyBtn() {
      return this.data.productBundle.tradingState === 'SIGN_UP_AND_WAIT_RECHARGE' || this.data.productBundle.tradingState === 'SIGN_UP_AND_PAYMENT_NOT_START';
    },
    showRechargeBtn() {
      return this.data.productBundle.tradingState === 'SIGN_UP_AND_WAIT_RECHARGE' || this.data.productBundle.tradingState === 'RECHARGE_WAIT';
    },
    getTradingStateClass(tradingState) {
      switch (tradingState) {
        case 'NOT_SIGN_UP':
          return 'product_bundle_trading_state_normal';
        case 'SIGN_UP_AND_WAIT_RECHARGE':
          return 'product_bundle_trading_state_normal';
        case 'SIGN_UP_AND_PAYMENT_NOT_START':
          return 'product_bundle_trading_state_other';
        case 'COMPLETE_RECHARGE':
          return 'product_bundle_trading_state_normal';

        case 'SIGN_UP_NOT_START':
          return 'product_bundle_trading_state_other';
        case 'SIGN_UP_OVERTIME':
          return 'product_bundle_trading_state_other';

        case 'RECHARGE_NOT_START':
          return 'product_bundle_trading_state_other';
        case 'RECHARGE_WAIT':
          return 'product_bundle_trading_state_normal';
        case 'RECHARGE_OVERTIME':
          return 'product_bundle_trading_state_other';

        default:
          return '';
      }
    },
    getTradingState(tradingState) {
      switch (tradingState) {
        case 'NOT_SIGN_UP':
          return '未报名';
        case 'SIGN_UP_AND_WAIT_RECHARGE':
          return '已报名待缴费';
        case 'SIGN_UP_AND_PAYMENT_NOT_START':
          return '已报名未开始缴费';
        case 'COMPLETE_RECHARGE':
          return '已缴费';

        case 'SIGN_UP_NOT_START':
          return '报名未开始';
        case 'SIGN_UP_OVERTIME':
          return '报名已截止';

        case 'RECHARGE_NOT_START':
          return '缴费未开始';
        case 'RECHARGE_WAIT':
          return '待缴费';
        case 'RECHARGE_OVERTIME':
          return '缴费已截止';

        default:
          return '';
      }
    },
  }
}
</script>

<style scoped>

</style>